import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import Layout from '../components/layout';
import { CommonContext } from '../context';
import { checkLocation, getPrayerTime, prayerTimeSlugs } from '../utils';
import clsx from 'clsx';
import { GpsIcon, PrayDuaIcon } from '../custom-icons';

function PrayerTimePage({ location }) {
  const [prayerTimes, setPrayerTimes] = useState();
  const [address, setAddress] = useState();

  useEffect(() => {
    if (localStorage.getItem('location')) {
      const location = JSON.parse(localStorage.getItem('location'));
      setPrayerTimes(getPrayerTime(location));
      setAddress(location);
    } else {
      _checkLocation();
    }
  }, []);

  const _checkLocation = async () => {
    try {
      const location = await checkLocation();
      localStorage.setItem('location', JSON.stringify(location));
      setAddress(location);
      setPrayerTimes(getPrayerTime(location));
    } catch (e) {
      /** */
    }
  };
  return (
    <CommonContext.Provider value={{ location: location }}>
      <Layout
        title="Prayer Times"
        location={location}
        description="Know your local prayer time from Greentech Apps Foundation and perform your Salat on time."
        keywords={[
          'prayer',
          'country',
          'salat',
          'time',
          'waqt',
          'suhoor',
          'iftar',
          'fasting',
        ]}
      >
        <div className="flex justify-center px-6 py-6 md:py-16">
          <div className="w-full max-w-xl p-8 text-lg shadow-xl rounded-2xl">
            {prayerTimes ? (
              <div>
                {address && (
                  <div className="p-2 pb-4">
                    <h1 className="pb-2 text-4xl">
                      Today&apos;s Prayer time in {address.city},{' '}
                      {address.country}
                    </h1>
                    Date: {new Date().toLocaleDateString()}{' '}
                    <button onClick={_checkLocation}>
                      <GpsIcon />
                    </button>
                  </div>
                )}
                {prayerTimes && (
                  <div className="py-4 border-t border-b border-neutral-2">
                    {prayerTimeSlugs.map((item) => (
                      <div
                        key={item.slug}
                        className={clsx(
                          item.slug == prayerTimes.currentPrayer()
                            ? 'font-bold'
                            : '',
                          item.slug == prayerTimes.nextPrayer()
                            ? 'font-bold'
                            : '',
                          'px-2 grid grid-cols-2',
                        )}
                      >
                        <div>{item.label}</div>
                        <div>
                          {prayerTimes[item.slug]
                            .toLocaleTimeString()
                            .replace(':00', ' ')}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <p className="p-4 mt-4 text-sm rounded-lg bg-primary-1">
                  <quote className="italic">
                    &quot;And who are ˹properly˺ observant of their prayers.
                    These will be in Gardens, held in honour.&quot; - Sura Al
                    Ma&apos;arij 70:34-35
                  </quote>
                </p>
                <p className="absolute -top-11">
                  Know the prayer times of today and pray on time. You can also
                  learn about Suhoor and Iftar times based on Sunrise and Sunset
                  accordingly.
                  <br />
                </p>
                <p className="mt-4 text-xs text-neutral-5">
                  * These times are calculated based using the method
                  recommended by the Umm al-Qura University, Makkah and can
                  differ with your local time by some minutes.
                  <br />* Asr time calculation is based on Hanafi madhbab.
                </p>
              </div>
            ) : (
              <div className="flex items-center justify-center h-96">
                <PrayDuaIcon className="w-8 h-8 animate-pulse" />
              </div>
            )}
          </div>
        </div>
      </Layout>
    </CommonContext.Provider>
  );
}

PrayerTimePage.propTypes = {
  location: PropTypes.object,
};

export default PrayerTimePage;
